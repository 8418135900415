import React from "react"

import { CustomHowWeDoIt } from "../components/how-we-do-it"
import Layout from "../components/layout"
import { PurpleHeroSection } from "../components/purple-hero-sections"
import SEO from "../components/seo"
import { OtherServices } from "./product-strategy"

export default function MobileAppDevelopment() {
  return (
    <Layout tcoreinsight={true} insightCategory="mobile-app-development">
      <SEO
        title="Top rated industry standard mobile app development | Touchcore"
        description="We offer impactful mobile app development services on every device and platform. Talk to our mobile app experts today!"
      />
      <div className="bg-plightpink">
        <PurpleHeroSection
          titleFirst={"Mobile App"}
          titleSecond={"Development"}
          subtitle={"Mobile app development that impacts your business "}
          content={`Mobile app will help your business streamline operations, bring value to the demanding modern customers, and help you garner the right data for your business. Whether it is your first app or third, our over five year expertise in mobile app development will help you succeed with your mobile strategy.`}
          imageComponent={
            <div
              data-aos="fade-left"
              className="ml-auto w-full md:w-6/12 max-h-screen pt-10 pb-20 "
            >
              <img
                className="md:w-8/12 lg:w-1/2 lg:py-16 mx-auto"
                alt={""}
                src={require("../assets/Outline3.svg")}
              />
            </div>
          }
        />
        <OADS />
      </div>
      <ODP />
      <OtherServices />
    </Layout>
  )
}
const OADSData = [
  {
    title: "Android App Development",
    content:
      "We know how to develop compelling, user-friendly, powerful and scalable applications of any category. Touchcore’s full-stack Android developers are skilled with native and hybrid programming languages. This has given us the ability to build apps from simple to complex functionalities for various industries. We don’t just build apps; we deliver perfection with every app.",
  },
  {
    title: "IOS App Development",
    content:
      "We provide iOS app development services while adhering to industry standard best practices to ensure scalability of the applications. Touchcore has extensive expertise in iOS mobile app development.",
  },
  {
    title: "Hybrid app development",
    content:
      "We can help you create cross-platform web-based apps, establish real-time data sync with WebSockets, create a Proof-of-Concept app, and more. Our developers specialize in cross-platform application development which reduces the hassle of dealing with multiple source codes for different platforms, thus increasing efficiency and decreasing costs.",
    centered: true,
  },
]
const OADS = () => (
  <CustomHowWeDoIt
    title="Our App Development Services"
    subtitle="We are a comprehensive mobile app development company: user research, design, development, develops, and continous security."
    bg={false}
    data={OADSData}
  />
)

const ODPData = [
  {
    small: true,
    title: "Research",
    content:
      "Once the client reaches us with the app idea, we do in -depth analysis on the basis of demographics, behavior patterns, and target audience to refine the idea into a solid basis for an application.",
  },
  {
    small: true,

    title: "Wireframing",
    content:
      "Based on the client’s requirements, We will create the Wireframe of the visualized idea that clearly defines the user’s journey.",
  },
  {
    small: true,

    title: "Prototyping",
    content:
      "We make a set of prototypes for the app that serves as a quick source to view how the app works.",
  },
  {
    small: true,

    title: "Design & Development",
    content:
      "Once the client reaches us with the app idea, we do in-depth analysis on the basis of demographics, behavior patterns, and target audience to refine the idea into a solid basis for an application.",
  },
  {
    small: true,

    title: "Testing and QA",
    content:
      "We rigorously test the performance of the developed app based on Industry’s standards to evaluate it’s responsiveness, scalability, and stability.",
  },
  {
    small: true,

    title: "Deployment to Live Server",
    content:
      "Once the developed app fulfills your expectations, we take your app to live as per your launch plan.",
  },
]

const ODP = () => (
  <CustomHowWeDoIt
    title="Our Development Processes"
    bg={false}
    bgColor={false}
    cols="6"
    data={ODPData}
  />
)
